.SubNav {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5rem;
	padding: 1rem;
}

.SubNav .Item {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}

.SubNav .Icon {
	width: 48px;
}

.SubNav .Item:hover .Name {
	color: var(--app-primary-color);
}

.SubNav .Item:hover img {
	filter: invert(19%) sepia(66%) saturate(7121%) hue-rotate(270deg)
		brightness(94%) contrast(87%);
}

.SubNav .Item .Name {
	display: block;
	text-align: center;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.SubNav {
		gap: 2rem;
		border-radius: 10px;
		margin: 1rem;
		overflow-x: scroll;
		background: white;
		justify-content: space-between;
		align-items: flex-start;
	}

	.SubNav .Item {
		flex: 1 1;
	}

	.SubNav .Name {
		font-size: 12px;
	}

	.SubNav .Icon {
		width: 32px;
	}
}
