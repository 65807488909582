.offeringsSection {
	flex: 1;
	background: linear-gradient(180deg, #9442f7, #be92f4);
}

.offeringsSection * {
	color: white;
}

.icon {
	width: 5rem;
}
