.offeringsList {
	list-style: none;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.offeringsList {
		flex-wrap: wrap;
		flex-direction: column;
	}
}
