.main {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 90vh;
}

.contactCard {
	padding: 3rem;
	margin: 1rem;
	background: white;
	border-radius: 2rem;
	min-width: 50vw;
	box-shadow: 0px 0px 5px lightgray;
}

.contactCard h1 {
	text-align: center;
	color: black;
}

.contactCard p {
	text-align: center;
}

.contactForm div {
	margin: 1.5rem 0;
}

.contactForm input,
.contactForm textarea {
	font-size: 1.1rem;
	padding: 0.5rem;
	width: strech;
	width: -webkit-fill-available;
	width: -moz-available;
	border: 0;
	border-bottom: 1px solid var(--font-color);
}

.contactForm input:focus-visible,
.contactForm textarea:focus-visible {
	outline: 0;
	border-bottom: 2px solid var(--font-color);
}

.contactForm button {
	font-size: 1rem;
	padding: 0.7rem;
	border: 0;
	border-radius: 2rem;
	transition: all ease-out 0.3s;
}

.contactForm button:hover {
	box-shadow: 0px 0px 5px rgb(231, 231, 231);
	transform: translate(0px, -5px);
}

.contactForm button:active {
	transform: scale(1.1);
}
