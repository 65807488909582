.main {
	display: contents;
}

.main section {
	margin: 1rem;
	padding: 2rem;
}

.main section h1 {
	font-size: 3rem;
	line-height: 4rem;
	margin: 1rem;
	text-align: center;
}

.main .section > .content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	flex: 1;
}

.landing {
	text-align: center;
	display: grid;
	grid-template-columns: 50% 50%;
}

.landingText {
	margin: auto;
	font-size: 3.5rem;
	line-height: 4.5rem;
}

.offernigsContainer {
	margin: auto;
}

.offering {
	margin: 3rem;
	width: 60%;
}

.twoColumn {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
}
