.DropDown {
	position: absolute;
	list-style: none;
	background: var(--app-primary-color);
	color: white !important;
	border-top: 1px solid white;
	height: 0;
	margin: 0;
	padding: 0;
}

.DropDown .Item {
	display: none;
}

.DropDown .Item a {
	color: white;
	display: block;
	padding: 1rem;
}

.DropDown.show {
	height: initial;
	margin: initial;
	width: 12rem;
}

.DropDown.show .Item {
	display: revert;
}

.DropDown .Item:hover {
	background-color: white;
	border: 1px solid var(--app-primary-color);
}

.DropDown .Item:hover a {
	color: var(--app-primary-color);
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.DropDown {
	}

	.DropDown.show {
		width: 95vw;
		left: 2%;
	}
}
