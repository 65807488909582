.endorsementCard {
	padding: 1rem 3rem;
	margin: 1rem;
	background: white;
	border-radius: 2rem;
	max-width: 50vw;
	box-shadow: 0px 0px 5px lightgray;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.endorsementCard img {
	width: 20%;
	margin: 1rem;
}

.quoted::before {
	font-family: 'Times New Roman', Times, serif;
	display: block;
	content: '\201c';
	font-size: 3rem;
}

.quoted::after {
	font-family: 'Times New Roman', Times, serif;
	content: '\201c';
	font-size: 3rem;
	transform: rotate(180deg);
	display: block;
}

.description {
	font-style: italic;
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: justify;
	padding: 0 3rem;
}

.endorser {
	display: block;
	text-align: end;
	margin: 0.5rem 0;
}

.name {
	font-size: 1.2rem;
	font-weight: bold;
}

.name::before {
	content: '\2014\0020';
}

.role {
	font-size: 1rem;
	color: darkgray;
	font-weight: 400;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.endorsementCard {
		padding: 1rem;
		max-width: 80vw;
	}
	.description {
		padding: 0;
	}
}
