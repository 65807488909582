.rentalsNav {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	background-color: var(--app-primary-color);
	gap: 1.3rem;
}

.rentalsNav .item {
	min-width: 8rem;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.rentalsNav {
		justify-content: flex-start;
		overflow-x: scroll;
	}
}
