.clients {
	padding: 1rem;
}

.clients h3 {
	text-align: center;
}

.clientGallery {
	list-style: none;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0rem;
	width: 90vw;
}

.item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: 2rem;
	margin: 0 1rem;
	min-width: 20rem;
	scroll-snap-align: start;
	background: lightgray;
	min-width: 15rem;
	min-height: 15rem;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.clientGallery {
		padding: 0rem;
		width: 74vw;
		gap: 4rem;
	}

	.item {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 2rem;
		scroll-snap-align: start;
		background: lightgray;
	}

	.item img {
		max-width: 10rem;
	}
}
