.offering {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	margin: 1rem;
	max-width: 20rem;
}

.offering img {
	width: 7rem;
}

.offering * {
	text-align: center;
}
