:root {
	background: rgb(249 249 249);
	--font-color: rgb(64, 64, 64);
	--app-primary-color: blueviolet;
	scroll-behavior: smooth;
}

* {
	padding: 0;
	margin: 0;
	font-weight: 400;
	color: var(--font-color);
	line-height: 1.5rem;
	transition: all ease-in-out 0.2s;
	font-family: system-ui;
	font-synthesis: none;
	-moz-font-feature-settings: 'kern';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

a {
	text-decoration: none;
}

a:hover {
	font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0.5rem 0;
}

hr {
	height: 1px;
	border: 0;
	border-bottom: 1px solid var(--font-color);
}

main {
	min-height: 50vh;
}

.prevent-text-select {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
