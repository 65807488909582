.Main {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
}

.Row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5rem;
}

.Section {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	text-align: center;
	max-width: 65vw;
	padding: 2rem;
}

.Heading {
	font-size: 3rem;
	font-weight: 300;
	line-height: 4rem;
}

.Heading_3 {
	font-size: 2rem;
	font-weight: 400;
}

.Paragraph {
	font-size: 2em;
	line-height: 3rem;
	font-weight: 300;
	color: darkgray;
}

.MeritList {
	list-style: none;
	padding: 1rem;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.Section {
		min-height: 70vh;
		max-width: 100vw;
	}

	.Section:nth-child(even) {
		background-color: white !important;
	}

	.Heading {
		font-size: 2rem;
		line-height: 3rem;
	}

	.Paragraph {
		font-size: 1.5em;
		line-height: 2rem;
	}
}
