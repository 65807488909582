.header {
	background: white;
	padding: 0.7rem 2rem;
	display: grid;
	grid-template-columns: 50% 50%;
	box-shadow: 0px 0px 5px lightgray;
	scroll-snap-align: start;
}

.navLinksContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.NavLink.active {
	font-weight: bold;
	color: white;
	padding: 0.5rem;
	background-color: blueviolet;
	border-radius: 10px;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.header {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		gap: 1rem;
		padding: 1rem 0;
	}

	.header nav {
		gap: 1rem;
	}

	.CompanyLogo {
		width: 50vw;
	}
}
