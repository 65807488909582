.List,
.Item {
	list-style: none;
	padding: 0;
	width: 80%;
}

.Content {
	padding: 1rem;
}

.Icon {
	width: 48px;
}

.Description {
	color: rgb(114, 114, 114);
}

.CatalogURL {
	text-decoration: underline;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.List {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
