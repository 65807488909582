.main {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main h1 {
	margin: 2rem;
	line-height: 3rem;
	text-align: center;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.main {
		padding: 1rem;
	}
}
