.NavHeading {
	color: white;
	background: none;
	border: none;
	text-wrap: nowrap;
	padding: 1rem;
	font-size: 1rem;
}

.NavHeading:hover {
	background-color: white;
	color: var(--app-primary-color);
}

.NavItem img {
	filter: invert(1);
	width: 2rem;
}

.NavItem span {
	display: inline-block;
	color: white;
	text-wrap: nowrap;
}
