.Content {
	display: flex;
	max-width: 100vw;
	flex-wrap: wrap;
	justify-content: center;
}

.Heading {
	text-align: center;
	font-weight: 300;
	font-size: 2rem;
	margin: 0.5rem;
	text-transform: capitalize;
	padding: 0.5rem;
	line-height: 2.5rem;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
}
