.Page {
	display: grid;
	grid-template-columns: 50% 50%;
}

.Page section {
	min-height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 3rem;
}

.Heading {
	font-size: 2rem;
	font-weight: 200;
}

.Heading2 {
	font-size: 3rem;
	line-height: 3.5rem;
	font-weight: 400;
	background: linear-gradient(135deg, #3d3dff, #d11eff, #ff9d23);
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
}

.CatalogContainer {
	background-color: white;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.Page {
		display: block;
	}
}
