.footer {
	display: grid;
	grid-template-columns: 35% 25% 25% 15%;
	background: linear-gradient(135deg, #3d3dff, #d11eff, #ff9d23);
	padding: 4rem 2rem;
	box-shadow: 0px 0px 5px lightgray;
}

.footer * {
	color: white;
}

.Heading {
	font-size: 3rem;
	font-weight: 200;
	line-height: 4rem;
}

.section {
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.section a {
	display: block;
	margin: 0.5rem 0rem;
	text-decoration: underline;
	display: inline-flex;
	justify-items: flex-start;
	gap: 0.5rem;
	align-items: center;
}

.section address {
	font-style: inherit;
}

.section address p {
	margin-left: 0;
	margin-right: 0;
}

.section .h1 {
	font-size: 1.2rem;
	font-weight: 500;
	margin: 1rem 0rem;
	text-transform: uppercase;
}

.section .p {
	margin: 0.5rem;
}

.MapArrow {
	font-size: 1.2rem;
}

.Icons {
	width: 2rem;
	filter: invert(1);
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
	.footer {
		display: block;
	}

	.Heading {
		font-size: 2rem;
	}
}
