.ItemCard {
	box-shadow: 0px 0px 5px #dbdbdb;
	padding: 1rem 2rem;
	margin: 1rem;
	border-radius: 1rem;
	flex: 1 0 15rem;
	display: flex;
	flex-direction: column;
	background-color: white;
	justify-content: space-between;
	max-width: 25rem;
}

.Img {
	width: 15rem;
}

.Tags {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
}

.Tag {
	padding: 0 0.5rem;
	border-radius: 100px;
	color: var(--app-primary-color);
	text-transform: capitalize;
	font-size: 0.7rem;
	border: 1px solid;
	line-height: 1.3rem;
}

.Row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}

.Name {
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 500;
}
